
<template>
  <div class="share-cen">
    <!-- <swiper
      id="mySwiper"
      :options="swiperOption"
      ref="mySwiper"
      class="my-swipe"
    >
      <swiper-slide
        class="swiper-item"
        v-for="(item, index) in list"
        :key="index"
      >
        <img
          :src="item.src"
          alt=""
          class="swipe-img"
          ref="swipeImg"
        >

        <div
          class="swiper-qr-code"
          :id="'qrCode' + index"
          ref="qrCodeDiv"
        ></div>

        <div class="erweima">
          <div class="share-down">
            <img
              src="../../assets/image/share_down.png"
              alt=""
              style="width: 100%;"
              @click="onDown(item.src,index)"
            >
          </div>
          <div class="share-s">
            <img
              src="../../assets/image/share_img.png"
              alt=""
              style="width: 100%;"
              v-clipboard:copy="item.path"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
          </div>
        </div>
      </swiper-slide>
      <div
        class="swiper-pagination"
        slot="pagination"
      ></div>
      <div
        class="swiper-button-prev"
        slot="button-prev"
      ></div>
      <div
        class="swiper-button-next"
        slot="button-next"
      ></div>
    </swiper> -->
    <van-swipe
      class="my-swipe"
      :autoplay="3000"
      indicator-color="#F75D22"
    >
      <van-swipe-item
        v-for="(item, index) in list"
        :key="index"
      >
        <img
          :src="item.src"
          alt=""
          class="swipe-img"
          ref="swipeImg"
        >

        <div
          class="swiper-qr-code"
          :id="'qrCode' + index"
          ref="qrCodeDiv"
        ></div>

        <div class="erweima">
          <div class="share-down">
            <img
              src="../../assets/image/share_down.png"
              alt=""
              style="width: 100%;"
              @click="onDown(item.src,index)"
            >
          </div>
          <div class="share-s">
            <img
              src="../../assets/image/share_img.png"
              alt=""
              style="width: 100%;"
              v-clipboard:copy="item.path"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <!-- <img :src='qrcodeImg'> -->
  </div>

</template>
<script>
import QRCode from "qrcodejs2";
import { Toast } from "vant";

const PATH = window.location.origin;
export default {
  data() {
    return {
      swiperOption: {
        pagination: ".swiper-pagination",
        initialSlide: 0, //默认第几张
        loop: true, //循环
        autoplayDisableOnInteraction: false, //触摸后再次自动轮播
        autoplay: 2000, //每张播放时长3秒，自动播放
        speed: 1000, //滑动速度
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      list: [
        {
          src: require("../../assets/image/share1.png"),
          path: PATH + "/mall",
        },
        {
          src: require("../../assets/image/share2.png"),
          path: PATH + "/mall",
        },
      ],
      qrcodeImg: "",
    };
  },
  computed: {
    uid() {
      return this.$store.getters["user/userId"];
    },
  },
  created() {
    this.setEntrys();
  },
  methods: {
    setEntrys() {
      // 渲染模板二维码
      this.$nextTick(function () {
        this.list.forEach((item, index) => {
          const dom = "qrCode" + index;
          this.setQRCode(item.path + "?icode=" + this.uid, dom, index);
        });
      });
    },

    setQRCode(url, dom, index) {
      // 生成二维码
      const qrcode = document.getElementById(dom);

      // 清空旧数据，避免E二维码重复
      qrcode.innerHTML = "";
      var width = this.$refs.swipeImg[index].clientWidth;
      let hrefWidth = parseInt(width / 3);
      new QRCode(qrcode, {
        text: url, // 二维码链接
        width: hrefWidth, // 二维码宽度
        height: hrefWidth, // 二维码高度
        colorDark: "#333333", // 二维码颜色
        colorLight: "#ffffff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
      });
    },
    onDown(src, index) {
      //下载
      const dom = "qrCode" + index;
      const qrcode = document.getElementById(dom);

      var canvasData = qrcode.getElementsByTagName("canvas");
      let img = new Image();
      img.src = canvasData[0].toDataURL("image/jpg");

      this.combinedPoster(src, img, index);
    },

    combinedPoster(_url, href, index) {
      //画海报
      console.log("swipeIm", this.$refs.swipeIm);
      let that = this;
      var width = this.$refs.swipeImg[index].clientWidth;
      var height = this.$refs.swipeImg[index].clientHeight;
      console.log("kk", width, height);
      var c = document.createElement("canvas");
      c.width = width;
      c.height = height;
      var ctx = c.getContext("2d");
      //⾸先画上背景图
      //注意：H5中任何图⽚写⼊画布都是以img 例如（<img src="123.png">）,以下代码console.log() 出来就是它
      var img = new Image();
      img.src = _url;
      img.crossOrigin = "anonymous";
      // 加载完成执⾏
      //写⼊⽂本必须重新定义颜⾊，否则会被覆盖
      //填充颜⾊
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, width, height);
      //写⼊⽂字
      // ctx.fillStyle = "#000";
      // ctx.font = "30px 微软雅⿊";
      // ctx.fillText("长按识别⼆维码", 300, height - 60);

      let left = this.getLeft(index, width);
      let top = this.getTop(index, height);
      console.log("top", top);
      let hrefWidth = parseInt(width / 3);

      if (!this._isMobile()) {
        // hrefWidth = 130;

        left = parseInt(width / 2) - 60;
        top = height - 200;
      }

      img.onload = function () {
        //画⼊背景图
        ctx.drawImage(img, 0, 0, width, height);

        //画⼊⼆维码
        ctx.drawImage(href, left, top, hrefWidth, hrefWidth);
        //绘制完成,转为图⽚
        setTimeout(() => {
          let base64_path = c.toDataURL("image/jpg");
          that.qrcodeImg = base64_path;
          that.downLoad();
        }, 1000);
      };
      img.onerror = function (err) {
        console.log(err);
      };
    },
    getTop(index, h) {
      let top = h - 200;
      if (index === 1) {
        top = parseInt(h / 2) - 30;
      }
      return top;
    },
    getLeft(index, w) {
      let left = parseInt(w / 2) - 50;
      if (index === 1) {
        left = parseInt(w / 2) - 60;
      }
      return left;
    },
    downLoad() {
      //下载
      const oA = document.createElement("a");
      oA.download = "二维码"; // 设置下载的文件名，默认是'下载'
      oA.href = this.qrcodeImg;
      document.body.appendChild(oA);
      oA.click();
      oA.remove(); // 下载之后把创建的元素删除
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onCopy() {
      //复制
      var ua = window.navigator.userAgent.toLowerCase();

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信内
        Toast("点击右上角分享到朋友圈");
      } else {
        //微信外
        Toast("复制成功");
      }
    },
    onError() {
      Toast("复制失败");
    },
  },
};
</script>

<style lang='scss' >
.share-cen {
  width: 100%;
  position: relative;
  .my-swipe {
    width: 100%;

    .swipe-img {
      width: 100%;
      height: 100%;
    }
  }
}
.erweima {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 36px;
  margin: auto;
  overflow: hidden;
  padding: 0 20px;
  & > div {
    display: inline-block;
    width: 8%;
  }
  .share-s {
    float: right;
  }
}
.swiper-qr-code {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 13%;
  margin: auto;
  img {
    display: inline-block !important;
    // width: 130px;
    // height: 130px;
  }
}
#qrCode1 {
  bottom: 0;
  top: 45.5%;
}
// #qrCode0 {
//   img {
//     width: 58px;
//     height: 58px;
//   }
// }
.swiper-slide {
  position: relative;
}
.swiper-button-prev {
  background-image: url("../../assets/image/share_left.png");
}
.swiper-button-next {
  background-image: url("../../assets/image/share_left.png");
  transform: rotate(180deg);
}
</style>
